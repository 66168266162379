/**
 * Main page with routing
 */
import {Container, CssBaseline, Paper} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/core/styles";
import i18n from "i18next";
import {merge} from "lodash";
import React from "react";
import {I18nextProvider} from "react-i18next";
import {GlobalProvider, useGlobal} from "up-form";
import {createProviderTheme} from "../../themes";
import ChangePassword from "./ChangePassword";
import Footer from "./Footer";
import {useParams} from "react-router-dom";

const ProviderPage = () => {
  const {provider: providerParam, studentId} = useParams(),
    {providers, providerGroups, fallbackProvider} = useGlobal();
  const providerSlug = (Object.entries(providers).find(
    ([slug, {selmaName}]) => selmaName === providerParam || slug.localeCompare(providerParam, "en", {sensitivity: "base"}) === 0
  ) || [fallbackProvider])[0]; // ascertain correct provider slug given best match with weird selma version of a provider name

  const {group} = providers[providerSlug];
  const providerConfig = merge({slug: providerSlug}, fallbackProvider, group ? providerGroups[group] : {}, providers[providerSlug]);
  const {logo, selmaName} = providerConfig;
  /**
   * For pages under a specific provider, we add the namesapce of the provider as the default, falling back to
   * the school company group (if defined) and the default translations
   *
   */

  const i18forProvider = i18n.cloneInstance({
    ns: ["translation", group, providerSlug],
    defaultNS: providerSlug,
    fallbackNS: [group, "translation"]
  });

  return (
    <GlobalProvider
      provider={providerConfig}
      studentId={studentId}
      selmaProvider={selmaName} // the selma provider string is case sensitive so for the time being pass to API as is (yuk)
      logo={logo}
    >
      <CssBaseline />
      <ThemeProvider theme={(parent) => createProviderTheme(providerSlug)}>
        <I18nextProvider i18n={i18forProvider}>
          <Paper style={{display: "flex", flexDirection: "column", flex: "1 1 auto"}}>
            <Container style={{display: "flex", flex: "1 0 auto", justifyContent: "center", flexDirection: "column"}}>
              <ChangePassword />
            </Container>
            <Footer />
          </Paper>
        </I18nextProvider>
      </ThemeProvider>
    </GlobalProvider>
  );
};
export default ProviderPage;
