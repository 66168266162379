/**
 * App entry point, dispatch to each sub app
 */
import {CssBaseline, LinearProgress, ThemeProvider} from "@material-ui/core";
import i18n from "i18next";
import I18NextXhrBackend from "i18next-xhr-backend";
import React, {Suspense} from "react";
import {I18nextProvider, initReactI18next} from "react-i18next";
import {Route, BrowserRouter as Router, Switch, Redirect} from "react-router-dom";
import UpApi from "up-api";
import {GlobalProvider} from "up-form";
import config from "../../config";
import {rootTheme} from "../../themes";
import ErrorPage from "../error/ErrorPage";
import "./App.css";
import ProviderPage from "./ProviderPage";
import {init as analyticsInit} from "up-analytics";
import {createBrowserHistory} from "history";

// Pull a bunch of config settings for this  env
const {
    stage,
    upApi,
    analytics: {appName, instrumentationKey}
  } = config,
  {baseUri} = upApi;

const appHistory = createBrowserHistory();
analyticsInit({appName, history: appHistory, instrumentationKey});

UpApi.configure({
  baseUri,
  headers: {source: "student-services"},
  post: (uri) => {
    if (stage === "development") {
      if (/v1\/passcode\/.*/.test(uri)) return "/dummy/product.json";
    }
  }
});

i18n
  .use(I18NextXhrBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en",
    fallbackLng: "en",
    debug: true,
    ns: ["translation"],
    defaultNS: "translation",
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "em", "b", "i", "p", "dl", "dt", "dd", "ul", "ol", "li"]
    }
  });

const App = () => {
  return (
    <Suspense fallback={<LinearProgress />}>
      <GlobalProvider {...config.global}>
        <ThemeProvider theme={rootTheme}>
          <I18nextProvider i18n={i18n}>
            <CssBaseline />
            <Router history={appHistory}>
              <Switch>
                <Route exact path={"/error/:status"} component={ErrorPage} />
                <Route path={`/:provider/:studentId`} component={ProviderPage} />
                <Route>
                  <Redirect to="/error/404" />
                </Route>
              </Switch>
            </Router>
          </I18nextProvider>
        </ThemeProvider>
      </GlobalProvider>
    </Suspense>
  );
};

export default App;
