/**
 * Page for the change password functions - 'modal' passcode entry initially displayed
 * closes successfully with new jwt temporary token for api so password can be enabled
 */
import React, {useState} from "react";
import UpApi from "up-api";
import {useGlobal, useQuery} from "up-form";
import Passcode from "./Passcode";
import Password from "./Password";

function ChangePassword() {
  const [valid, setValid] = useState();
  const {
    links: {redirectOnSuccess}
  } = useGlobal();
  const {redirect} = useQuery();
  const location = redirect || redirectOnSuccess;
  return (
    <>
      <Passcode
        open={!valid}
        onClose={(jwt) => {
          UpApi.configure({accessToken: jwt});
          setValid(true);
        }}
      />
      <Password
        disabled={!valid}
        onChange={() => {
          if (location) document.location.href = location;
        }}
      />
    </>
  );
}

export default ChangePassword;
