/**
 * Footer bar
 */
import {Grid, Link as ExtLink, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {useTranslation} from "react-i18next";
import {useGlobal} from "up-form";
import {ReactComponent as Logo} from "./up-logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    padding: "1rem 5rem 0 5rem",
    fontSize: "xx-small",
    height: "5rem",
    borderTop: `solid 1px ${theme.palette.divider}`,
    width: "100%",
    opacity: 1,
    backgroundColor: theme.palette.background.paper,
    flexShrink: 0
  },
  logo: {
    marginRight: "0.5em",
    fill: theme.palette.text.secondary,
    width: theme.spacing(3),
    height: theme.spacing(3),
    verticalAlign: "middle"
  },
  links: {
    "& a:not(:last-child):after": {
      content: "'|'",
      padding: ".5em"
    }
  }
}));

export default function Footer(props) {
  const {t} = useTranslation(),
    classes = useStyles(),
    {
      links: {home}
    } = useGlobal();
  return (
    <Typography className={classes.root} variant="body2" component="footer">
      <Grid container justifyContent="space-between" alignContent="center" alignItems="center" spacing={2}>
        <Grid item xs="auto">
          <ExtLink target="_blank" rel="noopener" href={home} color="textSecondary">
            <Logo className={classes.logo} />
            {t("Footer.links.copyright")}
          </ExtLink>
        </Grid>
      </Grid>
    </Typography>
  );
}
