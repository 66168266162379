import {Grid, Paper, Button} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {ErrorDetails, useQuery} from "up-form";

const ErrorPage = () => {
  const {status} = useParams();
  const {message} = useQuery();
  const {t} = useTranslation();
  const history = useHistory();
  return (
    <Grid container justifyContent="center" alignItems="center" style={{height: "80vh"}}>
      <Paper variant="outlined">
        <ErrorDetails
          statusCode={status}
          message={message}
          actions={[
            <Button
              onClick={() => {
                history.goBack();
              }}
            >
              {t("ErrorPage.tryAgain")}
            </Button>
          ]}
        />
      </Paper>
    </Grid>
  );
};

export default ErrorPage;
