import {createTheme, responsiveFontSizes} from "@material-ui/core";
import {merge} from "lodash";
import {lighten, darken} from "@material-ui/core/styles/colorManipulator";
import common from "@material-ui/core/colors/common";
import {red, grey, green, orange, lightBlue} from "@material-ui/core/colors";

const {black, white} = common;
const defaultPalette = {
  success: green,
  warning: orange,
  error: red,
  info: lightBlue
};

// A root theme shared by all child pages

const rootDefaults = {
  spacing: 16,
  typography: {
    fontFamily: ["SimplonNorm", "Open Sans", "sans-serif"].join(","),
    useNextVariants: true,
    h1: {fontSize: "32px", fontWeight: 800},
    h2: {fontSize: "24px", fontWeight: 800},
    h3: {fontSize: "20px", fontWeight: 600},
    h4: {fontSize: "16px", fontWeight: 700},
    h5: {fontSize: "14px", fontWeight: 700},
    h6: {fontSize: "12px", fontWeight: 700}
  },
  palette: {
    primary: {
      main: "#006A90"
    },
    secondary: {
      main: "#555"
    },
    ...defaultPalette
  },
  status: {
    danger: "red"
  },

  overrides: {
    MuiLink: {
      root: {
        whiteSpace: "nowrap"
      }
    },
    MuiFormLabel: {
      root: {
        lineHeight: "1.25em",
        fontWeight: "bold"
      }
    },
    MuiButton: {
      root: {
        fontWeight: "600",
        borderRadius: "2em",
        lineHeight: "2.75em"
      }
    },
    MuiToggleButton: {
      root: {
        "&.Mui-disabled": {
          opacity: "50%"
        }
      }
    },
    MuiTypography: {
      root: {
        "& dt": {
          flexBasis: "20%",
          textAlign: "left",
          padding: "2px 4px",
          fontWeight: "bold"
        },
        "& dd": {
          flexBasis: "70%",
          flexGrow: 1,
          margin: 0,
          padding: "2px 4px"
        },
        "& dl": {
          display: "flex",
          flexFlow: "row wrap"
        }
      }
    },
    UpFieldSet: {
      legend: {
        fontWeight: "bold"
      }
    }
  }
};
const rootTheme = createTheme(rootDefaults);

const themeByProvider = {
  ichm: {
    typography: {
      fontFamily: "GraphickWide",
      h1: {fontFamily: "GraphickWideBold", color: "#000070", textTransform: "uppercase"},
      h2: {fontFamily: "GraphickWideBold", color: "#000070"},
      h3: {fontFamily: "GraphickWideBold", color: "#ED4036"},
      h4: {fontFamily: "GraphickWideBold", color: "#ED4036"},
      h5: {fontFamily: "GraphickWideBold", color: "#ED4036"},
      h6: {fontFamily: "GraphickWideBold", color: "#ED4036"},
      body1: {fontFamily: "Graphick"},
      body2: {fontFamily: "Graphick"},
      caption: {fontFamily: "Graphick"},
      subtitle1: {fontFamily: "Graphick"},
      subtitle2: {fontFamily: "Graphick"}
    },
    palette: {
      ...defaultPalette,
      type: "light",
      primary: {main: "#000070"},
      secondary: {main: "#ED4036"},
      text: {primary: "#000000", secondary: "#A6A6A6"},
      background: {
        default: white,
        paper: white
      }
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": {
            fontFamily: "GraphickWide",
            src: 'url("/providers/ichm/GraphikWide-Regular-Web.woff2") format("woff2")'
          }
        }
      }
    }
  },
  up: {
    palette: {
      ...defaultPalette,
      primary: {
        main: "#006990"
      },
      secondary: {
        main: "#006990"
      }
    }
  },
  yoobee: {
    palette: {
      ...defaultPalette,
      primary: {main: "#00B57E"},
      secondary: red
    }
  },
  "face-and-beauty": {
    palette: {
      ...defaultPalette,
      primary: {main: "#ed1748"},
      secondary: {main: black},
      background: {
        default: white
      }
    }
  },
  tcc: {
    palette: {
      ...defaultPalette,
      primary: {main: "#00a650"},
      secondary: {main: white},
      text: {primary: black, secondary: grey[900]},
      background: {
        default: white
      }
    }
  },
  nzcm: {
    palette: {
      ...defaultPalette,
      primary: {main: "#6d8a34"},
      secondary: {main: "#575756"},
      text: {primary: black, secondary: grey[900]},
      background: {
        default: "#252E33"
      }
    }
  },
  nzma: {
    palette: {
      ...defaultPalette,
      primary: {main: "#009FE3"},
      secondary: {main: "#14699E"}
    }
  },
  nzis: {
    palette: {
      ...defaultPalette,
      primary: {main: "#ec8d20"},
      secondary: {main: "#14699E"},
      background: {
        default: "#252E33"
      }
    }
  },
  nzst: {
    palette: {
      ...defaultPalette,
      primary: {main: "#FF29B6"},
      secondary: {main: "#b1b1b1"},
      background: {
        default: white,
        paper: white
      }
    }
  },
  elite: {
    palette: {
      ...defaultPalette,
      type: "dark",
      primary: {main: "#D3B987"},
      secondary: {main: "#14699E"},
      text: {primary: grey["A100"], secondary: grey["A100"]},
      background: {
        default: black,
        paper: black
      }
    }
  },
  "cut-above": {
    palette: {
      ...defaultPalette,
      type: "dark",
      primary: {main: "#F89307"},
      secondary: {main: white},
      text: {primary: white, secondary: grey[400]},
      background: {
        default: black,
        paper: "#3c3c3b"
      }
    }
  }
};

export {rootTheme};
export function createProviderTheme(provider) {
  console.debug("Creating theme for " + provider);
  const theme = responsiveFontSizes(createTheme(merge(rootDefaults, themeByProvider[provider]))),
    {
      palette: {
        type,
        tonalOffset,
        background: {paper},
        primary: {main}
      }
    } = theme;
  const calculatedTheme = merge(theme, {
    overrides: {
      MuiPaper: {
        ...[...Array(10)]
          .map((v, i) => ({
            backgroundColor: type === "dark" ? lighten(paper, (tonalOffset * i) / 16) : darken(paper, (tonalOffset * i) / 16)
          }))
          .reduce((a, v, i) => {
            a[`elevation${i}`] = v;
            return a;
          }, {})
      },
      MuiTypography: {
        root: {
          "& ul": {
            listStyle: "none",
            "& li::before": {
              content: '"\\2022"',
              color: main,
              fontWeight: "bold",
              display: "inline-block",
              width: "1em",
              marginLeft: "-1em",
              fontSize: "2em",
              verticalAlign: "middle"
            }
          },
          "& br": {
            marginBottom: theme.spacing(1)
          }
        }
      }
    }
  });
  console.debug("theme", calculatedTheme);
  return calculatedTheme;
}
