/**
 * Password change fields, submits to secured API to change password
 * Shows:
 *  a password field
 *  confirmfield
 * 	Generate a password button
 * 	Submit, with toast on API success
 *
 * Password strength options read from config
 */
import PropTypes from "prop-types";
import {Box, Button, CircularProgress, Grid, IconButton, LinearProgress, Paper, TextField} from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {passwordStrength} from "check-password-strength";
import {generate} from "generate-password";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import UpApi from "up-api/src/UpApi";
import {useGlobal, Message} from "up-form";
import {Redirect} from "react-router";
import {makeStyles} from "@material-ui/core";
import {green, orange, red, yellow} from "@material-ui/core/colors";
import {track} from "up-analytics";

const useStyles = makeStyles((theme) => ({
  strengthBar: ({strength}) => {
    const colors = [red, orange, yellow, green];
    const color = colors[Math.min(strength || 0, colors.length - 1)];
    return {
      backgroundColor: color[50],
      "& div": {
        backgroundColor: color[500]
      }
    };
  },
  logo: ({logoBg}) => ({
    objectFit: "contain",
    minWidth: "10em",
    maxWidth: "100%",
    maxHeight: "20vh"
  })
}));
export function Password({disabled, onChange}) {
  const {
    formOptions: {
      ChangePassword: {generatePassword, strengthOptions}
    },
    logo,
    logoBg,
    provider: {slug},
    studentId
  } = useGlobal();
  const {t} = useTranslation();

  const [visiblePassword, setVisiblePassword] = useState(false);
  const [{password = "", strength, status, isOk}, setPassword] = useState({});
  const [{confirm: passwordConfirm = "", isConfirmed}, setPasswordConfirm] = useState({});
  const [{pending, error, success} = {}, setChangePasswordRequest] = useState();
  const classes = useStyles({strength, logoBg});

  // Handle change to password and confirm fields
  function handleChange(password, confirm) {
    const {id: strength, value: strengthLabel} = password && passwordStrength(password, strengthOptions);
    const isBad = password && password.indexOf(studentId) >= 0; // bad if id is embedded
    const isTooWeak = !(strength > 0); // id is 0==0 week or 1-max (label returned)
    // Okay if it exceeds 'Too weak' and doesn't contain the id
    const isOk = !(isBad || isTooWeak);
    setPassword({
      password,
      strength,
      isOk,
      status: isBad ? t("ChangePassword.error.badPassword") : isTooWeak ? t("ChangePassword.error.tooWeak") : strengthLabel
    });
    setPasswordConfirm({confirm, isConfirmed: password && password === confirm});
  }

  // Actually change password with API
  function changePassword() {
    setChangePasswordRequest({pending: true});
    UpApi.updateAdAccountPassword(studentId, password)
      .then(() => {
        setChangePasswordRequest({success: true});
        setPassword({});
        setPasswordConfirm({});
        track("Password Changed", {provider: slug, studentId, password: password.replaceAll(/./g, "*")});
      })
      .catch((error) => setChangePasswordRequest({error}));
  }

  return (
    <Paper variant="outlined">
      <Box p="10%">
        <form>
          <Grid spacing={3} container direction="column" alignItems="center">
            <Grid item>{logo && <img className={classes.logo} src={logo} alt={slug} />}</Grid>
            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12} sm={8}>
                <TextField
                  autoComplete="new-password"
                  type={visiblePassword ? "text" : "password"}
                  name="password"
                  disabled={disabled}
                  required
                  fullWidth
                  variant="outlined"
                  label={t("ChangePassword.password.label")}
                  onChange={(event) => handleChange(event.target.value, passwordConfirm)}
                  value={password}
                  InputProps={{
                    endAdornment: (
                      <>
                        <IconButton onClick={() => setVisiblePassword(!visiblePassword)}>
                          {visiblePassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        {isOk && <CheckCircle htmlColor="green" />}
                      </>
                    )
                  }}
                  error={!!password && !isOk}
                  helperText={status}
                />
                {strength >= 0 && (
                  <LinearProgress value={(1 + strength) * 25} variant="determinate" classes={{root: classes.strengthBar}} />
                )}
              </Grid>

              <Grid item container alignItems="center" direction="column" xs={12} sm={4}>
                <Button
                  disabled={disabled}
                  size="small"
                  variant="contained"
                  onClick={() => {
                    var newPassword,
                      i = 100; // yuk, mutable! - loop getting new passwords until we get a strong one
                    do {
                      newPassword = generate(generatePassword);
                    } while (passwordStrength(newPassword).id < 3 && --i > 0);
                    i === 0 && console.error("Password generator returning weak passwords - check reason!");
                    handleChange(newPassword, newPassword);
                    setVisiblePassword(true);
                  }}
                >
                  {t("ChangePassword.generate.label")}
                </Button>
              </Grid>
            </Grid>
            <Grid item container spacing={2} alignItems="center" justifyContent="flex-start">
              <Grid item xs={12} sm={8}>
                <TextField
                  type={visiblePassword ? "text" : "password"}
                  fullWidth
                  autoComplete="new-password"
                  disabled={disabled}
                  required
                  variant="outlined"
                  name="passwordConfirm"
                  value={passwordConfirm}
                  onChange={(event) => handleChange(password, event.target.value)}
                  label={t("ChangePassword.passwordConfirm.label")}
                  InputProps={{
                    endAdornment: isConfirmed && <CheckCircle htmlColor="green" />
                  }}
                  error={!!passwordConfirm && !isConfirmed}
                  helperText={!!passwordConfirm && !isConfirmed && t("ChangePassword.error.mismatchedPassword")}
                />
              </Grid>
              <Grid item xs={12} sm={4} />
            </Grid>
            <Grid item container alignItems="center" direction="column">
              <Button
                disabled={disabled || pending || !(isOk && isConfirmed)}
                variant="contained"
                color="primary"
                onClick={changePassword}
                startIcon={pending && <CircularProgress />}
              >
                {t("ChangePassword.apply.label")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Message
        open={success}
        variant="success"
        message={t("ChangePassword.success")}
        onClose={() => {
          setChangePasswordRequest({});
          onChange && onChange();
        }}
      />
      {error && <Redirect to={`/error/${error.status || 500}?message=${error.message}`} />}
    </Paper>
  );
}

Password.propTypes = {
  disabled: PropTypes.bool, // True to show in disabled state
  onChange: PropTypes.func // function to call when password successfully changed
};

export default Password;
