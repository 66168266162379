/**
 * Accept a passcode from the user in a Drawer overlay
 * On first render calls the back end to request passcode
 * User can click resend to request another
 * Checks entered passcode and calls onClose when accepted
 */
import PropTypes from "prop-types";
import {Box, Button, CircularProgress, Drawer, Grid, makeStyles, TextField, Typography} from "@material-ui/core";
import Lock from "@material-ui/icons/Lock";
import jwtDecode from "jwt-decode";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Redirect} from "react-router-dom";
import UpApi from "up-api";
import {Message, useGlobal} from "up-form";
import {track} from "up-analytics";

const useStyles = makeStyles((theme) => ({
  passcode: {
    fontSize: "4rem",
    "& ::placeholder, & input": {
      textAlign: "center",
      margin: 0
    }
  },
  logo: ({logoBg}) => ({
    backgroundColor: logoBg,
    objectFit: "contain",
    minWidth: "10em",
    maxWidth: "100%",
    maxHeight: "20vh"
  })
}));

function Passcode({open, onClose}) {
  const {
    formOptions: {
      Passcode: {length, pattern}
    },
    logo,
    logoBg,
    provider: {slug},
    selmaProvider,
    studentId
  } = useGlobal();
  const {t} = useTranslation();
  const classes = useStyles({logoBg});

  // Rather odd, but drawer doesn't work unless it is initially closed on render
  const [delayOpen, setDelayOpen] = useState(false);

  const [{passcode, success, fail, pending: pendingPasscodeCheck}, setPasscodeCheck] = useState({});
  const [{error, pending: pendingPasscodeRequest, reason}, setPasscodeRequest] = useState({});
  const busy = pendingPasscodeCheck || pendingPasscodeRequest;

  const resendPasscode = useCallback(
    (reason = "") => {
      if (studentId) {
        const provider = selmaProvider || slug;
        setPasscodeRequest({pending: true});
        UpApi.createPasscodeSession(provider, studentId)
          .then(() => {
            setPasscodeRequest({reason});
            track("Passcode Requested", {provider, studentId, reason});
          })
          .catch((error) => setPasscodeRequest({error}));
      }
    },
    [selmaProvider, slug, studentId]
  );

  useEffect(() => {
    resendPasscode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Needed for Draw to open in opn state (material weirdness)
  useEffect(() => setDelayOpen(open), [open]);

  useEffect(() => {
    if (!pendingPasscodeCheck && passcode && passcode.length === length) {
      setPasscodeCheck({passcode, pending: true});
      UpApi.passcodeSession(studentId, passcode)
        .then(({sessionJwt}) => {
          try {
            // Not we are not cryptographically checking the token as it will be checked when used by API, just
            // looking at the expiry/format now to make sure it doesn't fail unexpectedly on password change
            const {exp} = jwtDecode(sessionJwt);
            const valid = exp && exp > new Date().getTime() / 1000;
            if (valid) {
              setPasscodeCheck({success: true});
              onClose(sessionJwt);
            } else {
              setPasscodeCheck({fail: true});
              resendPasscode(t("Passcode.error.passcodeExpired"));
            }
          } catch (e) {
            setPasscodeCheck({fail: true});
            resendPasscode(t("Passcode.error.badSessionToken"));
          }
        })
        .catch(({status, message}) => {
          setPasscodeCheck({fail: true});
          if (status !== 404) resendPasscode(t("Passcode.error.passcodeCheckError"));
        });
    }
  }, [passcode, length, onClose, t, studentId, pendingPasscodeCheck, resendPasscode]);

  return (
    <>
      <Drawer anchor="top" open={delayOpen} transitionDuration={1000} ModalProps={{closeAfterTransition: true}}>
        <Box p={2} style={{height: "100vh"}}>
          <form>
            <Grid spacing={2} direction="column" alignItems="center" container>
              {logo && (
                <Grid item xs>
                  <img className={classes.logo} src={logo} alt={slug} />
                </Grid>
              )}
              <Grid item xs>
                <Typography variant="body1" style={{fontSize: "x-large"}}>
                  {t("Passcode.passcode.label", {length})}
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    classes: {root: classes.passcode}
                  }}
                  inputProps={{
                    pattern: pattern.toString(),
                    maxLength: length,
                    size: length
                  }}
                  disabled={busy}
                  name="passcode"
                  variant="outlined"
                  placeholder={"-".repeat(length)}
                  size="medium"
                  required
                  onChange={(event) => setPasscodeCheck({passcode: event.target.value.replace(pattern, "")})}
                  value={passcode || ""}
                  error={!!fail}
                  fullWidth
                  helperText={fail && t("Passcode.error.badPasscode")}
                  autoComplete="one-time-code"
                />
              </Grid>
              <Button onClick={() => resendPasscode(t("Passcode.info.passcodeSent"))}>{t("Passcode.resend.label")}</Button>
              <Grid item>{busy ? <CircularProgress size="5rem" /> : <Lock fontSize="large" />}</Grid>
            </Grid>
          </form>
        </Box>
      </Drawer>
      {!!error && <Redirect push to={`/error/${error.status || 500}?message=${error.message}`} />}
      {!selmaProvider && !slug && <Redirect to={`/error/404?message=${t("Passcode.error.badProvider")}`} />}
      {!studentId && <Redirect to={`/error/404?message=${t("Passcode.error.badUser")}`} />}
      <Message
        open={success}
        variant="success"
        message={t("Passcode.info.success")}
        onClose={() => setPasscodeCheck({})}
        anchorOrigin={{horizontal: "center", vertical: "top"}}
      />
      <Message open={!!reason} variant={fail ? "warning" : "success"} message={reason} onClose={() => setPasscodeRequest({})} />
    </>
  );
}

Passcode.propTypes = {
  onClose: PropTypes.func, // Function to call on suucessful passcode acceptance
  open: PropTypes.any // Show in open state
};

export default Passcode;
