/**
 * Configuration by exports values per environment, as set by REACT_APP_STAGE
 */
import {loadClientConfig} from "up-form";
const lodash = require("lodash");
export const {name, version} = require("../package.json");
export const stage = process.env.REACT_APP_STAGE || "unknown";
export const isDev = process.env.REACT_APP_STAGE !== "production";
export const buildId = process.env.REACT_APP_BUILD_ID;
export const branch = process.env.REACT_APP_BRANCH_NAME;
export const commit = process.env.REACT_APP_COMMIT;
export const publicUrl = process.env.PUBLIC_URL || window.location.origin;
export const clientConfigKey = `config:${name}:${version}`;
// Allow build-time override from variable
const upApiOverride = process.env.REACT_APP_UP_API_URI;

const data = {
  // Use these values if not provided by stage-specific values
  default: {
    analytics: {
      appName: process.env.REACT_APP_APP_NAME,
      instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY
    },
    global: {
      buildId,
      branch,
      stage,
      commit,
      isDev,
      links: {
        home: "http://up.education",
        terms: "https://www.up.education/content/uploads/2019/08/UPIC010_Privacy-Policy_SCREEN.pdf"
      },
      logo: "/providers/up-online/logo.svg",
      hostMap: {
        soe: "((.*\\.)?soe\\.edu\\.au)|(sut-apply.*\\.uponline\\.education).*",
        "up-online": "(.*\\.)?uponline\\.up\\.education|localhost.*"
      },
      fallbackProvider: "up-education",
      formOptions: {
        ChangePassword: {
          generatePassword: {
            length: 12,
            numbers: true,
            uppercase: true,
            lowercase: true,
            symbols: "@#$%!-",
            strict: true,
            excludeSimilarCharacters: true
          },
          strengthOptions: [
            {
              id: 0,
              value: "Is too weak",
              minDiversity: 0,
              minLength: 0
            },
            {
              id: 1,
              value: "Weak",
              minDiversity: 3,
              minLength: 10
            },
            {
              id: 2,
              value: "Medium",
              minDiversity: 3,
              minLength: 12
            },
            {
              id: 3,
              value: "Strong",
              minDiversity: 4,
              minLength: 12
            }
          ]
        },
        Passcode: {
          length: 4,
          pattern: /\D/g
        }
      },

      providerGroups: {
        // Usefule defaults for providers
        "nzma-group": {
          links: {
            terms: "/providers/nzma-group/nzma-group-terms-and-conditions.pdf"
          }
        },
        "yoobee-group": {
          logo: "/providers/yoobee-group/Yoobee_Colleges_Ltd_Lockup.png",
          logoBg: "transparent",
          links: {
            terms: "/providers/yoobee-group/yoobee-terms-conditions.pdf"
          }
        }
      },
      providers: {
        "up-education": {
          links: {
            home: "http://up.education",
            terms: "https://www.up.education/content/uploads/2019/08/UPIC010_Privacy-Policy_SCREEN.pdf"
          },
          logo: "/providers/up/up-education-logo.svg",
          logoBg: "#FFF"
        },
        yoobee: {
          group: "yoobee-group",
          links: {
            home: "http://yoobee.ac.nz",
            terms: "/providers/yoobee/yoobee-terms-conditions.pdf"
          },
          selmaName: "Yoobee"
        },
        nzst: {
          group: "yoobee-group",
          links: {
            home: "http://nzschooloftourism.co.nz"
          },
          selmaName: "NZST"
        },
        nzma: {
          group: "nzma-group",
          links: {
            home: "https://www.nzma.ac.nz/"
          },
          logo: "/providers/nzma/nzma-logo.png",
          logoBg: "#FFF",
          selmaName: "NZMA"
        },
        ichm: {
          favicon: "/providers/ichm/ichm-logo.svg",
          logo: "/providers/ichm/ichm-logo.svg",
          logoBg: "#000070",
          selmaName: "ICHM"
        },
        "cut-above": {
          group: "yoobee-group",
          links: {
            home: "https://www.cutabove.ac.nz/"
          }
        },
        elite: {
          group: "yoobee-group",
          links: {
            home: "https://www.elitebeautyschool.co.nz/"
          }
        },
        "culinary-collective": {
          group: "nzma-group",
          links: {
            home: "https://culinarycollective.co.nz"
          },
          logo: "/providers/tcc/tcc-logo.png",
          logoBg: "#FFF"
        },
        "nz-institute-of-sport": {
          group: "nzma-group",
          links: {
            home: "https://www.nzis.co.nz/"
          },
          logo: "/providers/nzis/nzis-logo.png",
          logoBg: "#FFF"
        }
      }
    }
  },

  // Use these values if stage is not specified
  unknown: {},

  // Stage specific
  local: {
    upApi: {
      baseUri: upApiOverride || "http://localhost:3030/"
    },
    global: {}
  },

  development: {
    upApi: {
      baseUri: upApiOverride || "https://up-int-apim-dev.azure-api.net/"
    },
    global: {}
  },

  test: {
    upApi: {
      baseUri: upApiOverride || "https://up-int-apim-test.azure-api.net/"
    },
    global: {}
  },
  uat: {
    upApi: {
      baseUri: upApiOverride || "https://up-int-apim-uat.azure-api.net/"
    },
    global: {}
  },
  production: {
    upApi: {
      baseUri: "https://up-int-apim-prod.azure-api.net/"
    },
    global: {}
  }
};

export const baseConfig = lodash.merge({}, data["default"], data[stage]);
const config = isDev ? lodash.merge({}, baseConfig, loadClientConfig(clientConfigKey)) : baseConfig;
export default config;
